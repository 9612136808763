<template>
  <b-container fluid>
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img :src="orgData.org_profile_img" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>

      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="orgData.org_cover_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="orgData.org_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="12" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="orgData.org_cover_img" style="width:100%" />
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="mb-2">
            <span class="primary-color">{{orgData.org_name}}</span>
          </h3>
          <h3 class="mb-0">
            Sign Up
          </h3>
          <p>
            Enter your email address to sign up.
          </p>
          <form class="mt-4">
            <div class="form-group">
              <label for="exampleInputEmail2">Email address</label>
              <input v-model="userEmail" type="email" class="form-control mb-0" id="exampleInputEmail2" placeholder="Enter email">
            </div>
            <div class="d-inline-block w-100">
              <button type="button" class="btn btn-primary float-right" @click="register">
                Register
              </button>
            </div>
            <div class="sign-info">
              <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ path: 'login'}">Sign In</router-link></span>
              <ul class="iq-social-media mt-4">
                <li>
                  <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                </li>
              </ul>
            </div>
          </form>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"
import { Organisations } from "../FackApi/api/organisation.js"

export default {
  name: "Register",
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration Response",
    userEmail: "",
    userName: "",
    showPasswordField: false,
    operatingsystem: window.__DEVICE_OS__,
    orgData: {
      id: 54238,
      org_id: "GIDE_ORG_1",
      user_id: "1",
      acc_id: "1",
      org_name: "GIDE.AI",
      type: "ORG",
      org_desc: `<h1><strong style="color: rgb(226, 38, 44);">GIDE.AI</strong><strong>: </strong>Your Gateway to Scholarships and Career Success</h1><p><strong style="color: rgb(230, 0, 0);">Brought to you by Asia's largest Student and Parent community - High School Moms (HSM)</strong></p><p>Access futuristic career assessment, profile building, test preparation, great scholarships, and career guidance all in one integrated experience.</p><p><br></p>`,
      org_website_email: null,
      org_admin_email: "admin@gide.ai",
      org_admision_email: "admissions@gide.ai",
      org_admission_requirement: null,
      org_top_alums: null,
      org_major_industries_around: null,
      org_clubs: null,
      org_college_basis_app: null,
      org_city: null,
      org_country: null,
      org_founding_year: null,
      org_application_deadline: null,
      org_intake_count: null,
      org_intake_type: null,
      org_currency: null,
      org_avg_tuition_min: null,
      org_avg_tuition_max: null,
      org_scholarship_min: null,
      org_scholarship_max: null,
      org_tnc: 0,
      created_on: "2023-11-22T23:26:47.000Z",
      deleted: 0,
      country_id: null,
      org_profile_img: "https://web.gide.ai/img/GideWhiteNRed.2533ee45.jpeg",
      org_cover_img: "https://gide-assets.s3.amazonaws.com/GIDECover.White.png",
      org_state: null,
      org_uid: "",
      org_domain: "web.gide.ai",
      org_domain_is_validated: 99,
      country_name: null,
      currency_name: null,
      currency_code: null
    },
    propOpenedInModal: false
  }),
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  async mounted () {
    await this.getOrgDetails()
    if (this.userData && this.userData.user_email) {
      this.userEmail = this.userData.user_email
      this.show_user_email = true
    }
  },
  methods: {
    async getOrgDetails () {
      try {
        // Do not get the org_details if running on localhost, for localdns we have to set the org_domain in DB
        if (process.env.VUE_APP_ENV == "local") {
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
          return
        }

        const orgDomain = window.location.hostname
        if (this.saasOrgData) {
          console.log("Found org data in cache ", this.saasOrgData)
          // Found in Cache
          this.orgData = this.saasOrgData
        }
        else {
          const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
          if (!orgDetails.resp_status) {
            // set the details of GIDE_ORG_1 as default
            this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
            return
          }

          this.orgData = orgDetails.resp_data.data
          // set the org data in local storage for saving in user table
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)

          // set the source for the user
          let source = this.orgData.org_uid
          if (this.sourceData) {
            source = this.orgData.org_uid + "_" + this.sourceData
          }

          this.$store.dispatch("SourceTracker/setCodeAction", source)
        }
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    async register () {
      let userObj = {
        "user_email": this.userEmail
      }
      let userRegisterResp = await User.userRegister(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userRegisterResp)
      if (!userRegisterResp.resp_status) {
        this.toastVariant = "danger"
      }
      else {
        let userProfileData = {
          user_id: userRegisterResp.resp_data.user_id,
          user_email: this.userEmail,
          login_process: "email"
        }
        this.$store.dispatch("User/setUserProfileAction", { ...this.userData, ...userProfileData })
        setTimeout(() => {
          this.$router.push("/activate")
        }, 1000)
      }
    }
  }
}
</script>
